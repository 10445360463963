import { CSSTransition } from 'react-transition-group';

import styles from './FormError.module.scss';

const FormError = ({ error }) => {
	const hasError = !!error;

	return (
		<CSSTransition
			in={hasError}
			timeout={{
				appear: 0,
				enter: 230,
				exit: 0,
			}}
			classNames={{
				enter: styles.onEnter,
				enterActive: styles.onEnterActive,
				exit: styles.onExit,
				exitActive: styles.onExitActive,
			}}
			unmountOnExit
		>
			<div className={styles.info}>{error}</div>
		</CSSTransition>
	);
};

export default FormError;
